<template>
  <div :class="['relative min-h-[100vh]',themeStyles(theme)]">
    <div class="max-wrapper relative p-8 py-12">
      <BlockContent v-if="content" :content="content" />
      <FormValidation
        :id="form.formGuid"
        class="flex size-full flex-col justify-between"
        :validation-schema="validationSchema"
        :initial-values="initialValues"
        :form-name="formName"
        @submit="onSubmit"
        @model-value="($event: ContactFormData) => formValues = $event"
      >
        <div class="input-con">
          <label class="font-bold" for="firstname">First Name *</label>
          <Field id="firstname" name="firstname" type="text" placeholder="John" />
          <ErrorMessage name="firstname" />
        </div>
        <div class="input-con">
          <label class="font-bold" for="lastname">Last Name *</label>
          <Field id="lastname" name="lastname" type="text" placeholder="Doe" />
          <ErrorMessage name="lastname" />
        </div>
        <div class="input-con">
          <label class="font-bold" for="email">Email *</label>
          <Field id="email" name="email" type="email" placeholder="Type your email" />
          <ErrorMessage name="email" />
        </div>
        <div class="input-con">
          <label class="font-bold" for="phone">Phone Number</label>
          <Field id="phone" name="phone" type="tel" placeholder="Type your Phone Number" />
          <ErrorMessage name="phone" />
        </div>
        <div class="relative mb-6 flex h-auto w-full flex-wrap items-center md:mb-[2rem]">
          <label class="font-bold" for="message">Message</label>
          <span class="mb-4 w-full">tell us why you're reaching out</span>
          <Field
            id="message"
            class="w-full"
            as="textarea"
            name="message"
            cols="100"
            rows="10"
          />
          <ErrorMessage name="message" />
        </div>
      </FormValidation>
      <div v-if="loading" class="success bg-background relative flex h-auto w-full items-center justify-center">
        <h4 class="flex items-center">
          <svg class="-ml-1 mr-4 size-14 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          Processing...
        </h4>
      </div>
      <div v-if="finished" class="success bg-background relative flex h-auto w-full items-center justify-center">
        <div class="flex w-full flex-wrap justify-center">
          <h4 class="w-full text-center">
            Thank you {{ formValues?.firstname }}
          </h4>
          <p class="w-full text-center">
            We have received your inquiry and will be in touch soon
          </p>
        </div>
      </div>
      <div v-if="hasBeenAnError" class="error bg-background relative flex h-auto w-full items-center justify-center">
        <h1>Something has gone wrong please try again</h1>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable no-empty-pattern */
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import axios from 'axios'
import type { BlockContent, Theme } from '~/types'

interface ContactFormData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  message: string;
}

interface Props {
  theme: Theme;
  content: BlockContent;
  form: {
    portalId: string;
    formGuid: string;
  }
}
const props = defineProps<Props>()

const loading = ref(false)
const finished = ref(false)
const hasBeenAnError = ref(false)
const formName = 'contactForm'
const phoneRegExp = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
const formValues = ref<ContactFormData>()
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: ''
}
const validationSchema = {
  firstname: yup.string().required().label('First Name'),
  lastname: yup.string().required().label('Last Name'),
  email: yup.string().required().email().label('Email Address'),
  phone: yup.string().when([], ([], schema) => {
    return formValues.value?.phone ? schema.matches(phoneRegExp, 'Phone number is not valid').label('Phone Number') : schema.notRequired()
  }),
  message: yup.string().label('Message')
}

function resetStatesAfterDelay (seconds: number) {
  return new Promise(resolve =>
    setTimeout(() => {
      hasBeenAnError.value = false
      loading.value = false
      finished.value = false
      resolve('reset')
    }, seconds * 1000)
  )
}
async function getIp () {
  const data = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then(res => res.text())
  if (!data) { return }

  const ipv4Regex = /((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])/
  const ipv6Regex = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/
  const ipv4 = data.match(ipv4Regex)?.[0]
  const ipv6 = data.match(ipv6Regex)?.[0]

  return ipv6 ?? ipv4
}
async function prepareFormData (formData: ContactFormData) {
  if (!window || !document || !location) { return }
  const fields = Object.entries(formData).map((val) => {
    const name = val[0]
    const value = val[1]
    const objectTypeId = '0-1'
    return { objectTypeId, name, value }
  })

  const pageUri = location?.host === 'localhost:3000' ? 'projectopal.co' : `${location?.host}${location?.pathname}`
  const ipAddress = await getIp()

  const context = {
    hutk: getCookie('hubspotutk'),
    pageUri,
    pageName: document?.title,
    ipAddress
  }

  return {
    fields,
    context,
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Project Opal to store and process my personal data.'
      }
    }
  }
}

async function onSubmit (formData: ContactFormData) {
  const data = await prepareFormData(formData)
  const { portalId, formGuid } = props.form

  loading.value = true

  try {
    const res = (await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, data))?.data as {inlineMessage: string}
    if (res?.inlineMessage) {
      loading.value = false
      finished.value = true
      removeItem(formName)
      resetStatesAfterDelay(5)
    }
  } catch (error) {
    await resetStatesAfterDelay(0)
    hasBeenAnError.value = true
    await resetStatesAfterDelay(5)
  }
}
</script>
<style lang="scss" scoped>
.max-wrapper {
  @apply py-12 h-full;
}
.step {
  @apply relative gap-4 mt-8 h-[calc(100%-2rem)] md:mt-20 flex w-full md:h-[calc(100%-5rem)] overflow-auto flex-wrap;
}
label {
  @apply mb-2;
}
input,
textarea,
select {
  color-scheme: dark;
  @apply text-main bg-background-800 w-full min-w-full h-12 leading-[3rem] px-3 py-2 rounded-md border-solid border-[1px] border-background-800;
  margin: 0;
  display: block;
}
textarea {
  height: auto;
}
span[role='alert'] {
  @apply bg-red-500 absolute top-[calc(100%+2.5rem)] py-4 md:py-0 text-[10px] md:text-[16px] rounded-md border-red-500 border-solid border-[1px] text-main px-2 h-6 flex items-center justify-center
}
.prefix-wrapper {
  input {
    @apply min-w-0;
  }
}
.input-con {
  @apply relative flex w-full mb-12 md:mb-[4.5rem] h-12 flex-wrap items-center;
  &.textarea { @apply h-auto; }
}
.multi-select { @apply relative flex w-full mb-12 }
.cliped-corner {
  @apply relative;
  clip-path: inset(0 0 0 0 round 0 1rem 0 1rem);
  &::before {
    clip-path: inset(0 0 0 0 round 0 1rem 0 1rem);
  }
}
</style>
