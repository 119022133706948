<template>
  <form v-bind="$attrs">
    <slot />
    <POButton :id="`${formName}-submit-btn`" btn-style="po" color="opal" @click="onSubmit">
      Submit
    </POButton>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'

const props = defineProps({
  formName: {
    type: String,
    required: true,
    default: () => 'formData'
  },
  initialValues: {
    type: Object,
    required: true
  },
  validationSchema: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['submit', 'modelValue'])

const { values, handleSubmit, setFieldValue } = useForm({
  initialValues: props.initialValues,
  validationSchema: props.validationSchema
})

watch(values, () => {
  emit('modelValue', values)
  setItem(props.formName, values, 7 * 24 * 60 * 1000)
})
const onSubmit = handleSubmit((values, e) => {
  e.evt?.preventDefault()
  emit('submit', values)
})
onBeforeMount(() => {
  const formData = getItem(props.formName)
  if (formData) {
    Object.entries(formData).forEach((val) => {
      const key = val[0]
      const value = val[1]
      setFieldValue(key, value)
    })
  }
})
</script>
